<template>
    <div class="relative overflow-x-auto bg-gray-900 p-12">
      <!-- Titre principal du tableau -->
      <h1 class="text-3xl font-bold text-white text-center mb-8">Tableau de suivi</h1>
      
      <!-- Tableau des mois et bénéfices -->
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg border border-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">Mois</th>
            <th scope="col" class="px-6 py-3">Bénéfice Total (€)</th>
            <th scope="col" class="px-6 py-3">Message</th>
          </tr>
        </thead>
        <tbody>
          <!-- Utilisation de v-for pour afficher chaque mois avec le style souhaité -->
          <tr
            v-for="mois in moisList"
            :key="mois.mois"
            class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
          >
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{ mois.mois }}
            </th>
            <td class="px-6 py-4">
              {{ mois.total_benefice !== null ? parseFloat(mois.total_benefice).toFixed(2) + ' €' : 'Aucune donnée' }}
            </td>
            <td class="px-6 py-4">{{ generateGeneralMessage(mois.total_benefice) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  // Liste des mois avec leurs bénéfices
  const moisList = ref([
    { mois: 'Janvier', total_benefice: null },
    { mois: 'Février', total_benefice: null },
    { mois: 'Mars', total_benefice: null },
    { mois: 'Avril', total_benefice: null },
    { mois: 'Mai', total_benefice: null },
    { mois: 'Juin', total_benefice: null },
    { mois: 'Juillet', total_benefice: null },
    { mois: 'Août', total_benefice: null },
    { mois: 'Septembre', total_benefice: null },
    { mois: 'Octobre', total_benefice: null },
    { mois: 'Novembre', total_benefice: null },
    { mois: 'Décembre', total_benefice: null }
  ]);
  
  // Fonction pour générer un message général en fonction du bénéfice
  const generateGeneralMessage = (benefice) => {
    if (benefice === null) {
      return "Aucune donnée disponible pour ce mois.";
    } else if (benefice > 0) {
      const positiveMessages = [
        "Les résultats de ce mois sont positifs.",
        "Les performances économiques sont bonnes.",
        "Mois avec un bénéfice important."
      ];
      return positiveMessages[Math.floor(Math.random() * positiveMessages.length)];
    } else if (benefice < 0) {
      const negativeMessages = [
        "Les résultats ce mois sont négatifs.",
        "Des pertes sont enregistrées ce mois-ci.",
        "Mois difficile en termes de résultats."
      ];
      return negativeMessages[Math.floor(Math.random() * negativeMessages.length)];
    } else {
      const neutralMessages = [
        "Aucun bénéfice ni perte pour ce mois.",
        "Le mois a été neutre en termes de résultats."
      ];
      return neutralMessages[Math.floor(Math.random() * neutralMessages.length)];
    }
  };
  </script>
  
  <style>
  body {
    font-family: 'Inter', sans-serif;
  }
  </style>
  