<template>
    <section class="relative flex items-center justify-center min-h-screen bg-white dark:bg-gray-900 p-4">
        <!-- Contenu au-dessus de la lueur de fond -->
        <div class="relative z-10 py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12 p-6">
            <!-- Logo au centre -->
            <div class="mb-6">
                <img src="@/assets/maintenance/NutsBetting-LogoW.svg" alt="Logo" class="mx-auto w-24 h-24">
            </div>

            <!-- Notification -->
            <a href="#"
                class="inline-flex flex-wrap justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                role="alert">
                <span class="text-xs bg-purple-600 rounded-full text-white px-4 py-1.5 mr-3">Important</span>
                <span class="text-sm font-medium flex-1 min-w-0 truncate">Bêta prévue en décembre.</span>
                <!-- Heroicon Arrow Right -->
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
            </a>

            <!-- Heading -->
            <h1 class="mb-4 text-4xl tracking-tight leading-none text-gray-900 md:text-4xl lg:text-4xl dark:text-white">
                Application en cours de développement
            </h1>
            <p class="mb-8 text-md font-normal text-gray-500 lg:text-lg sm:px-16 xl:px-48 dark:text-gray-400">
                Elle vous offrira des conseils en paris sportifs basés sur des <strong>analyses statistiques
                    avancées</strong> et des stratégies personnalisées.
                Elle proposera également un <strong>système de bankroll</strong>, vous permettant de créer vos propres
                statistiques et de gérer vos paris à votre manière.
                Nous serons <strong>à l'écoute de notre communauté</strong> pour améliorer continuellement l'expérience
                et ajouter de nouvelles fonctionnalités.
            </p>

            <!-- Buttons -->
            <div
                class="flex flex-col items-center justify-center mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 w-full text-center">
                <a href="https://buy.stripe.com/14k5m3eCV4z24dq6op"
                    class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900">
                    Réserver son abonnement
                    <!-- Heroicon Arrow Right -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 -mr-1 w-5 h-5" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                </a>
                <!-- Button Watch Video -->
                <a href="/stats"
                    class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                    <!-- Heroicon Play -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 -ml-1 w-5 h-5" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M14.752 11.168l-6.304-3.553A1 1 0 0 0 7 8.553v6.894a1 1 0 0 0 1.448.895l6.304-3.553a1 1 0 0 0 0-1.79z" />
                    </svg>
                    Découvrir mes statistiques
                </a>
            </div>

        </div>
    </section>
</template>

<script setup>


</script>

<style scoped>
/* Ajout de style supplémentaire si nécessaire */
</style>