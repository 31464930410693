import { createRouter, createWebHistory } from 'vue-router';
import maintenanceModeV2 from '../views/maintenanceModeV2.vue';
import Statistiques from '../views/maintenanceStats.vue';
import Beta from '../views/maintenancePay.vue';

const routes = [
    {
        path: '/',
        name: 'Maintenance',
        meta: { title: 'Maintenance - NutsBetting' },
        component: maintenanceModeV2
    },
    {
        path: '/stats',
        name: 'Statistiques',
        meta: { title: 'Maintenance - NutsBetting' },
        component: Statistiques
    },
    {
        path: '/beta',
        name: 'Beta',
        meta: { title: 'Maintenance - NutsBetting' },
        component: Beta
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// Écoute l'événement de navigation avant chaque changement de route
router.beforeEach((to, from, next) => {
    // Détermine le titre de la route, ou utilise un titre par défaut si non spécifié
    const title = to.meta.title || 'NutsBetting';

    // Met à jour le titre de la page immédiatement
    document.title = title;

    // Continue la navigation
    next();
});

export default router;
