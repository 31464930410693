<template>
  <div :class="themeClass">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      theme: 'dark',  // Par défaut, nous commençons en "dark"
    };
  },
  computed: {
    themeClass() {
      return this.theme === 'dark' ? 'dark' : '';
    }
  },
  mounted() {
    // Vérifie si un thème est stocké dans le localStorage
    const storedTheme = localStorage.getItem('theme');
    
    // Si un thème est trouvé dans le localStorage, applique-le
    if (storedTheme) {
      this.theme = storedTheme;
    } else {
      // Si aucun thème n'est stocké, par défaut le thème est "dark"
      this.theme = 'dark';
      localStorage.setItem('theme', 'dark');
    }

    // Ajoute la classe "dark" au body si le thème est "dark"
    if (this.theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  },
  watch: {
    theme(newTheme) {
      // Stocke la préférence du thème dans le localStorage
      localStorage.setItem('theme', newTheme);

      // Gère l'application ou suppression de la classe "dark" sur le body
      if (newTheme === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  },
  methods: {
    toggleTheme() {
      // Permet de basculer entre le thème "dark" et "light"
      this.theme = this.theme === 'dark' ? 'light' : 'dark';
    }
  }
};
</script>

<style>
/* Dark mode */
.dark body {
  background-color: #111827;
  color: white;
}

body {
  background-color: white;
  color: black;
}
</style>
