import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://nutsbetting.com/', // Remplacez par votre URL de base
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
});

export default instance;
