<template>
    <div class="flex flex-col justify-center items-center min-h-screen w-full p-4">
        <!-- Conteneur pour les deux graphiques -->
        <div class="flex flex-col lg:grid lg:grid-cols-2 gap-10 justify-center h-full w-full max-w-7xl">
            <!-- Bloc pour Accès Anticipé (affiché en premier sur mobile) -->
            <div class="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mx-auto w-full lg:w-10/12 flex flex-col h-full">
                <h1 class="text-left text-2xl text-gray-900 dark:text-white mb-4">Telegram Public</h1>
                <div class="flex justify-between mb-5">
                    <!-- Bénéfice accès anticipé -->
                    <div>
                        <h5 class="text-left leading-none text-3xl text-gray-900 dark:text-white">
                            {{ anticipLastBankrollValue !== null && anticipLastBankrollValue !== undefined ?
                                parseFloat(anticipLastBankrollValue).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) + '€' : '1000€' }}
                        </h5>
                        <p class="text-sm font-normal text-gray-500 dark:text-gray-400">Bénéfice des membres gratuits
                        </p>
                    </div>
                    <!-- Pourcentage de variation accès anticipé -->
                    <div class="flex items-center px-2.5 py-0.5 text-base font-semibold text-center" :class="{
                        'text-green-500 dark:text-green-500': anticipBankrollChangePercent >= 0,
                        'text-red-500 dark:text-red-500': anticipBankrollChangePercent < 0
                    }">
                        {{ anticipBankrollChangePercent !== null && anticipBankrollChangePercent !== undefined ?
                            anticipBankrollChangePercent : '0' }}%
                        <svg class="w-3 h-3 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 10 14" :class="{
                                'rotate-180': anticipBankrollChangePercent < 0
                            }">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M5 13V1m0 0L1 5m4-4 4 4" />
                        </svg>
                    </div>
                </div>
                <div id="anticip-chart" class="flex-grow"></div>
            </div>

            <!-- Bloc pour Public -->
            <div class="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mx-auto w-full lg:w-10/12 flex flex-col h-full">
                <h1 class="text-left text-2xl text-gray-900 dark:text-white mb-4">Telegram VIP</h1>
                <div class="flex justify-between mb-5">
                    <!-- Bénéfice public -->
                    <div>
                        <h5 class="text-left leading-none text-3xl text-gray-900 dark:text-white">
                            {{ publicLastBankrollValue !== null && publicLastBankrollValue !== undefined ?
                                parseFloat(publicLastBankrollValue).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) + '€' : '1000€' }}
                        </h5>
                        <p class="text-sm font-normal text-gray-500 dark:text-gray-400">Bénéfice des membres de la bêta
                        </p>
                    </div>
                    <!-- Pourcentage de variation public -->
                    <div class="flex items-center px-2.5 py-0.5 text-base font-semibold text-center" :class="{
                        'text-green-500 dark:text-green-500': publicBankrollChangePercent >= 0,
                        'text-red-500 dark:text-red-500': publicBankrollChangePercent < 0
                    }">
                        {{ publicBankrollChangePercent !== null && publicBankrollChangePercent !== undefined ?
                            publicBankrollChangePercent : '0' }}%
                        <svg class="w-3 h-3 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 10 14" :class="{
                                'rotate-180': publicBankrollChangePercent < 0
                            }">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M5 13V1m0 0L1 5m4-4 4 4" />
                        </svg>
                    </div>
                </div>
                <div id="public-chart" class="flex-grow"></div>
            </div>
        </div>

        <!-- Bouton retour en bas pour mobile et en haut à droite pour grand écran -->
        <a href="/"
            class="lg:absolute lg:top-4 lg:right-4 mt-6 lg:mt-0 inline-flex justify-center items-center py-3 px-5 text-base font-medium text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900">
            Retour vers l'accueil
            <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 -mr-1 w-5 h-5" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
            </svg>
        </a>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ApexCharts from 'apexcharts';
import axios from '../axios';  // Importation d'axios pour les requêtes HTTP

// Variables pour les graphiques
const anticipChartData = ref([]);
const publicChartData = ref([]);

// Variables pour les bénéfices accès anticipé et public
const anticipLastBankrollValue = ref(1000);
const publicLastBankrollValue = ref(1000);

// Pourcentage de variation accès anticipé et public
const anticipBankrollChangePercent = ref(0);
const publicBankrollChangePercent = ref(0);

// Bankroll de départ
const startingBankroll = 1000;

// Fonction pour récupérer les données depuis l'API pour accès anticipé
const fetchAnticipChartData = async () => {
    try {
        const { data } = await axios.post('/api/test.php');
        if (Array.isArray(data)) {
            let bankroll = startingBankroll;
            const bankrollEvolution = data.map(benefice => {
                bankroll += parseFloat(benefice);
                return parseFloat(bankroll.toFixed(2));
            });

            anticipChartData.value = bankrollEvolution;
            anticipLastBankrollValue.value = bankrollEvolution[bankrollEvolution.length - 1] || startingBankroll;
            anticipBankrollChangePercent.value = (((anticipLastBankrollValue.value - startingBankroll) / startingBankroll) * 100).toFixed(2) || 0;
            renderAnticipChart(); // Render chart after data is fetched
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des données accès anticipé :", error);
    }
};

// Fonction pour récupérer les données depuis l'API pour public
const fetchPublicChartData = async () => {
    try {
        const { data } = await axios.post('/api/test2.php');
        if (Array.isArray(data)) {
            let bankroll = startingBankroll;
            const bankrollEvolution = data.map(benefice => {
                bankroll += parseFloat(benefice);
                return parseFloat(bankroll.toFixed(2));
            });

            publicChartData.value = bankrollEvolution;
            publicLastBankrollValue.value = bankrollEvolution[bankrollEvolution.length - 1] || startingBankroll;
            publicBankrollChangePercent.value = (((publicLastBankrollValue.value - startingBankroll) / startingBankroll) * 100).toFixed(2) || 0;
            renderPublicChart(); // Render chart after data is fetched
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des données public :", error);
    }
};

// Fonction pour afficher le graphique Accès Anticipé avec condition de couleur et fond personnalisé
const renderAnticipChart = () => {
    const lineColor = anticipLastBankrollValue.value >= startingBankroll ? '#10B981' : '#EF4444';
    const fillColor = anticipLastBankrollValue.value >= startingBankroll ? '#D1FAE5' : '#FEE2E2';

    const options = {
        series: [
            {
                name: 'Accès Anticipé',
                data: anticipChartData.value
            }
        ],
        chart: {
            height: 300,
            maxWidth: '100%',
            type: 'area',
            background: '#1F2937',  // Couleur de fond en gray-800
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true
                },
                autoSelected: 'zoom'
            },
            cursor: {
                crosshairs: {
                    show: true,  // Active le crosshair lors du survol
                    position: 'front', // Crosshair en avant
                    width: 1,
                    color: '#B3B3B3',  // Couleur du crosshair
                    opacity: 0.9
                }
            }
        },
        stroke: {
            width: 6,
            curve: 'smooth',
            colors: [lineColor]
        },
        markers: {
            size: 8,
            colors: ['#FFFFFF'],
            strokeColors: [lineColor],
            strokeWidth: 3,
            hover: {
                size: 10,
                fillColor: '#1F2937'  // Fond des marqueurs au survol
            },
        },
        xaxis: {
            categories: Array.from({ length: anticipChartData.value.length }, (_, i) => i + 1),
            labels: {
                style: {
                    colors: '#9CA3AF'
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
            labels: {
                formatter: value => '€' + parseFloat(value).toFixed(2),
                style: {
                    colors: '#9CA3AF'
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value, { dataPointIndex, w }) {
                return dataPointIndex === w.config.series[0].data.length - 1 ? value + '€' : '';
            },
            background: {
                enabled: true,
                foreColor: '#FFFFFF',
                borderRadius: 5,
                padding: 5,
                opacity: 1,
                borderWidth: 1,
                borderColor: lineColor,
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    color: '#000',
                    opacity: 0.25
                }
            },
            offsetY: -10,
            style: {
                fontSize: '14px',
                colors: [lineColor],
                backgroundColor: lineColor
            }
        },
        grid: {
            padding: {
                left: 30,
                right: 30,
                top: 20,
                bottom: 20,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.55,
                opacityTo: 0,
                colorStops: [
                    {
                        offset: 0,
                        color: fillColor,
                        opacity: 0.7,
                    },
                    {
                        offset: 100,
                        color: fillColor,
                        opacity: 0,
                    },
                ],
            }
        },
        theme: {
            mode: 'dark',
            palette: 'palette1'  // Utilisation de la palette 1
        }
    };

    const chart = new ApexCharts(document.getElementById('anticip-chart'), options);
    chart.render();
};

// Fonction pour afficher le graphique Public avec condition de couleur et fond personnalisé
const renderPublicChart = () => {
    const lineColor = publicLastBankrollValue.value >= startingBankroll ? '#10B981' : '#EF4444';
    const fillColor = publicLastBankrollValue.value >= startingBankroll ? '#D1FAE5' : '#FEE2E2';

    const options = {
        series: [
            {
                name: 'Public',
                data: publicChartData.value
            }
        ],
        chart: {
            height: 300,
            maxWidth: '100%',
            type: 'area',
            background: '#1F2937',  // Couleur de fond en gray-800
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true
                },
                autoSelected: 'zoom'
            },
            cursor: {
                crosshairs: {
                    show: true,  // Active le crosshair lors du survol
                    position: 'front', // Crosshair en avant
                    width: 1,
                    color: '#B3B3B3',  // Couleur du crosshair
                    opacity: 0.9
                }
            }
        },
        stroke: {
            width: 6,
            curve: 'smooth',
            colors: [lineColor]
        },
        markers: {
            size: 8,
            colors: ['#FFFFFF'],
            strokeColors: [lineColor],
            strokeWidth: 3,
            hover: {
                size: 10,
                fillColor: '#1F2937'  // Fond des marqueurs au survol
            },
        },
        xaxis: {
            categories: Array.from({ length: publicChartData.value.length }, (_, i) => i + 1),
            labels: {
                style: {
                    colors: '#9CA3AF'
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
            labels: {
                formatter: value => '€' + parseFloat(value).toFixed(2),
                style: {
                    colors: '#9CA3AF'
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value, { dataPointIndex, w }) {
                return dataPointIndex === w.config.series[0].data.length - 1 ? value + '€' : '';
            },
            background: {
                enabled: true,
                foreColor: '#FFFFFF',
                borderRadius: 5,
                padding: 5,
                opacity: 1,
                borderWidth: 1,
                borderColor: lineColor,
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    color: '#000',
                    opacity: 0.25
                }
            },
            offsetY: -10,
            style: {
                fontSize: '14px',
                colors: [lineColor],
                backgroundColor: lineColor
            }
        },
        grid: {
            padding: {
                left: 30,
                right: 30,
                top: 20,
                bottom: 20,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.55,
                opacityTo: 0,
                colorStops: [
                    {
                        offset: 0,
                        color: fillColor,
                        opacity: 0.7,
                    },
                    {
                        offset: 100,
                        color: fillColor,
                        opacity: 0,
                    },
                ],
            }
        },
        theme: {
            mode: 'dark',
            palette: 'palette1'  // Utilisation de la palette 1
        }
    };

    const chart = new ApexCharts(document.getElementById('public-chart'), options);
    chart.render();
};

// Lorsque le composant est monté, on récupère les données et on affiche les graphiques
onMounted(async () => {
    await fetchAnticipChartData();  // Récupération des données pour accès anticipé
    await fetchPublicChartData();  // Récupération des données pour public
});
</script>


<style>
body {
    font-family: 'Inter', sans-serif;
}
</style>
